import React, { Fragment, useEffect } from "react";

// CUSTOM COMPONENTS
import { Text, Button, BackButton } from "components";
// CONTEXT
import { useLanguage } from "context/Language.context";
// CONSTANTS
import { guideInfo } from "./SoftwareGuide.constants";

export const SoftwareGuideTabContent = ({ lang }: { lang: string }) => {
  const { language, toggleLanguage } = useLanguage();

  useEffect(() => {
    toggleLanguage(lang);
  }, [lang]);

  function handleClickDownload(fileName: string) {
    const file_path = fileName;
    const a = document.createElement("a");
    a.href = file_path;
    a.download = file_path.substring(file_path.lastIndexOf("/") + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return (
    <Fragment>
      <Text font={"black"} size={"SIZE22"} color={"pink"}>
        {guideInfo[language].softwareGuide}
      </Text>
      <div className="software-guide-page--video">
        <iframe
          width="100%"
          height="315"
          src={guideInfo[language].guideVideoLink}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <div className="software-guide-page--title-text">
        <Text font={"book"} size={"SIZE14"} color={"white"}>
          {guideInfo[language].download}
        </Text>
      </div>
      <div className="software-guide-page--manual-block">
        <Text font={"book"} size={"DEFAULT"} color={"pink"}>
          {guideInfo[language].manual}
        </Text>
        <div className="software-guide-page--title-download">
          <Button
            onClick={() =>
              handleClickDownload("/assets/files/Kavaca_SmartCut_Manual.pdf")
            }
            color={"pink"}
          >
            {guideInfo[language].btnText}
          </Button>
        </div>
      </div>
      <div className="software-guide-page--manual-block">
        <Text
          font={"book"}
          size={"DEFAULT"}
          transform={"uppercase"}
          color={"grey"}
        >
          {guideInfo[language].plotters}
        </Text>
        <div className="software-guide-page--title-download">
          <Button
            onClick={() =>
              handleClickDownload("/assets/files/Compatible_plotter_list.pdf")
            }
            color={"grey"}
          >
            {guideInfo[language].btnText}
          </Button>
        </div>
      </div>
      <div className="software-guide-page--white-space">
        <Text font={"book"} size={"SIZE14"} color={"white"}>
          {guideInfo[language].questions}
        </Text>
      </div>
      <Text font={"book"} size={"SIZE14"} color={"white"}>
        {"support@nanoshine-group.com"}
      </Text>
    </Fragment>
  );
};
