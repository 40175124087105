import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as BackIcon } from "assets/icons/arrow_back.svg";

export const BackButton: FunctionComponent = () => {
  return (
    <div>
      <Link to={"/"} className={"route-button--back--button"}>
        <BackIcon className={"route-button--back--icon"} />
        {"BACK"}
      </Link>
    </div>
  );
};
