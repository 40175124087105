import React, { FunctionComponent } from "react";

// CUSTOM COMPONENTS
import { KSTab, BackButton } from "components";
import { SoftwareGuideTabContent } from "./SoftwareGuidTabContent";

export const SoftwareGuide: FunctionComponent = () => {
  return (
    <div className={"software-guide-page"}>
      <BackButton />
      <KSTab
        tabHeaders={[{ title: "English" }, { title: "Español" }]}
        tabPanels={[
          <SoftwareGuideTabContent lang="eng" key="eng" />,
          <SoftwareGuideTabContent lang="esp" key="esp" />,
        ]}
      />
    </div>
  );
};
