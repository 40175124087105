export const guideInfo: any = {
  eng: {
    softwareGuide: "SOFTWARE GUIDE",
    download: "Please, download the manual in PDF format here:",
    manual: "KAVACA SMARTCUT MANUAL",
    questions:
      "For all questions and recommendations,\nplease contact our support.\nWe will be happy to assist you!",
    support: "support@nanoshine-group.com",
    plotters: "List of compatible plotters",
    btnText: "DOWNLOAD",
    guideVideoLink:
      "https://www.youtube.com/embed/6Ashp58B6uw?si=O9K1-QzzHO6cowF_",
  },
  esp: {
    softwareGuide: "GUÍA DEL SOFTWARE",
    download: "Por favor, descargá el manual en formato PDF aquí:",
    manual: "MANUAL DE KAVACA SMARTCUT",
    questions:
      "Para cualquier consulta o recomendación, \nno dudes en contactar a nuestro equipo de soporte. \n¡Estaremos encantados de ayudarte!",
    support: "support@nanoshine-group.com",
    plotters: "LISTA DE PLOTTERS COMPATIBLES",
    btnText: "DESCARGAR",
    guideVideoLink: "https://www.youtube.com/embed/aY2fqgn-NDM",
  },
};
