"use client";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

// INTERFACES
import { CpTabPropsTypes } from "./Tab.interfaces";

export const KSTab = ({
  tabHeaders,
  tabPanels,
  defaultIndex = 0,
}: CpTabPropsTypes) => {
  return (
    <div className="ks-tab">
      <Tabs defaultIndex={defaultIndex}>
        <TabList>
          {tabHeaders.map((item, i) => {
            return (
              <Tab key={i} disabled={item.isDisabled}>
                {item.title}
              </Tab>
            );
          })}
        </TabList>

        {tabPanels.map((Panel, i) => (
          <TabPanel key={i} className="react-tabs__tab-panel">
            {Panel}
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
};
