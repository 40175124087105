import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import classNames from "classnames";
import { Link, useSearchParams } from "react-router-dom";
import { AuthContext } from "App";
import { CardItemsType, ICards } from "./HomePage.interfaces";
// CONSTANTS
import { TEXT_MOCK } from "../ProductPage/ProductPage.constants";
import { HOME_PAGE_CARDS } from "./HomePage.constants";
// CUSTOM COMPONENTS
import { Button, Dialog, Text } from "components";
// IMAGES
import { ReactComponent as ErrorIcon } from "assets/icons/error_icon.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/success_icon.svg";

export const HomePage: FunctionComponent = () => {
  const [{ authToken }] = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isPaymentDialogOpen, setPaymentIsDialogOpen] = useState(false);
  const [isServiceError, setIsServiceError] = useState({
    isOpen: false,
    message: "",
  });
  const email = searchParams.get("email") || null;
  const paymentStatus = searchParams.get("payment_status") || null;

  useEffect(() => {
    if (paymentStatus === "COMPLETED" && !isPaymentDialogOpen) {
      setPaymentIsDialogOpen(true);
    }
  }, [paymentStatus, isPaymentDialogOpen]);

  function handleClosePaymentDialog() {
    searchParams.delete("payment_status");
    searchParams.delete("email");
    setSearchParams(searchParams);
    setPaymentIsDialogOpen(false);
  }

  function handleCloseServiceDialog() {
    setIsServiceError({
      isOpen: false,
      message: "",
    });
  }

  function handleShowServiceError(message?: string) {
    if (message) {
      setIsServiceError({
        isOpen: true,
        message,
      });
    }
  }

  const renderCard = (cardItem: ICards, cardIndex: number) => {
    const isMultiple = cardItem.items.length > 1;
    return (
      <div key={`${cardItem.side}-${cardIndex}`} className={"home-page-card"}>
        {cardItem.items.map((item: CardItemsType, itemIndex: number) => {
          const isPinkBg = item?.bg === "pink";
          const cardClass = classNames("home-page-card-item", {
            "home-page-card-half": isMultiple,
            "home-page-card-wide": !isMultiple,
            "home-page-card-item--pink": isPinkBg,
          });
          const textClass = classNames("home-page-text", {
            "home-page-text-left-bottom": cardItem.side === "left-bottom",
            "home-page-text-right-bottom": cardItem.side === "right-bottom",
          });
          return (
            <Link
              onClick={() => handleShowServiceError(item.errorMessage)}
              key={`${item.title}-${itemIndex}`}
              to={item.path}
              className={cardClass}
            >
              {item.img !== "" ? (
                <img
                  className={"home-page-img"}
                  alt={item.title}
                  src={item.img}
                />
              ) : null}
              <Text size={"SIZE20"} className={textClass}>
                {item.title}
              </Text>
            </Link>
          );
        })}
      </div>
    );
  };

  return (
    <div className={"home-page"}>
      <div className={"home-page-wrapper"}>
        {HOME_PAGE_CARDS.map((card: ICards, index) => renderCard(card, index))}
      </div>
      <Dialog
        icon={<SuccessIcon />}
        isOpen={isPaymentDialogOpen}
        message={`${TEXT_MOCK[2]} - ${email}`}
        renderControls={() => {
          return (
            <div className={"home-page--button-between"}>
              <Button
                onClick={handleClosePaymentDialog}
                color={"grey"}
                size={"small"}
              >
                {"OK"}
              </Button>
            </div>
          );
        }}
      />
      <Dialog
        icon={<ErrorIcon />}
        isOpen={isServiceError.isOpen}
        message={isServiceError.message}
        renderControls={() => {
          return (
            <div className={"home-page--button-between"}>
              <Button
                onClick={handleCloseServiceDialog}
                color={"grey"}
                size={"small"}
              >
                {"OK"}
              </Button>
            </div>
          );
        }}
      />
      <div className={"global--log"}>
        <div>
          {"authToken:  "}
          {authToken}
        </div>
      </div>
    </div>
  );
};
