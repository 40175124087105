"use client";
import { createContext, useState, ReactNode, useContext } from "react";

export interface LanguageContextType {
  language: string;
  toggleLanguage: (lang: string) => void;
}
interface LanguageProps {
  children: ReactNode;
}

const LanguageContext = createContext<LanguageContextType>({
  language: "eng",
  toggleLanguage: (lang: string) => null,
});

const LanguageProvider = ({ children }: LanguageProps) => {
  const [language, setLanguage] = useState<string>("eng");

  const toggleLanguage = (lang: string) => {
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error("useLanguage must be used within an LanguageProvider");
  }
  return context;
};

export { LanguageProvider, LanguageContext };
