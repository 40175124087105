import React, { FunctionComponent } from "react";

// CUSTOM COMPONENTS
import { Text, Flex, BackButton } from "components";
// CONSTANTS
import { PUZZLE_BLOCK, TEXT_MOCK } from "./JoinPage.constants";

export const JoinPage: FunctionComponent = () => {
  return (
    <div className={"join-us-page"}>
      <BackButton />
      <Text font={"black"} size={"SIZE22"} color={"pink"}>
        {TEXT_MOCK[0]}
      </Text>
      <div>
        <Text font={"black"} size={"SIZE20"}>
          {TEXT_MOCK[1]}
        </Text>
      </div>
      <div className={"join-us-page--img"}>
        <img
          alt={"join-us-page--image"}
          src={"/assets/image/KVC_JOIN_US_1-100.jpg"}
        />
      </div>
      <Text font={"light"} size={"DEFAULT"}>
        {TEXT_MOCK[2]}
      </Text>
      <div className={"join-us-page--sub-title"}>
        <Text font={"light"} size={"DEFAULT"}>
          {TEXT_MOCK[3]}
        </Text>
      </div>
      <Flex className={"join-us-page--puzzle"} justifyContent={"spaceBetween"}>
        {PUZZLE_BLOCK.map((puzzleItem, index) => {
          return (
            <Flex
              alignItems={"center"}
              flexDirection={"column"}
              justifyContent={"center"}
              key={`${puzzleItem.title}-${index}`}
              className={"join-us-page--puzzle--item"}
            >
              <Text color={"white"} font={"black"} size={"SIZE22"}>
                {puzzleItem.title}
              </Text>{" "}
              <br />
              <Text
                className={"join-us-page--puzzle--item--text"}
                transform={"uppercase"}
                color={"white"}
                font={"black"}
                size={"DEFAULT"}
              >
                {puzzleItem.message}
              </Text>
            </Flex>
          );
        })}
      </Flex>

      <Flex justifyContent={"spaceBetween"}>
        <div className={"kavaca-ion-page--half-item"}>
          <div className={"join-us-page--img"}>
            <img
              alt={"join-us-page--image"}
              src={"/assets/image/KVC_JOIN_US_2-100.jpg"}
            />
          </div>
        </div>
        <div className={"kavaca-ion-page--half-item"}>
          <div className={"join-us-page--img"}>
            <img
              alt={"join-us-page--image"}
              src={"/assets/image/KVC_JOIN_US_3-100.jpg"}
            />
          </div>
        </div>
      </Flex>

      <div className={"join-us-page--text"}>
        <Text font={"light"} size={"DEFAULT"}>
          {TEXT_MOCK[5]}
        </Text>
      </div>
      <Text font={"light"} size={"DEFAULT"}>
        {TEXT_MOCK[4]}
      </Text>

      <Flex justifyContent={"spaceBetween"}>
        <div className={"join-us-page--half-item"}>
          <div className={"join-us-page--img"}>
            <img
              alt={"join-us-page--image"}
              src={"/assets/image/KVC_JOIN_US_4-100.jpg"}
            />
          </div>
          <div className={"join-us-page--img"}>
            <img
              alt={"join-us-page--image"}
              src={"/assets/image/KVC_JOIN_US_6-100.jpg"}
            />
          </div>
        </div>
        <div className={"join-us-page--half-item"}>
          <div className={"join-us-page--img"}>
            <img
              alt={"join-us-page--image"}
              src={"/assets/image/KVC_JOIN_US_5-100.jpg"}
            />
          </div>
          <div className={"join-us-page--img"}>
            <img
              alt={"join-us-page--image"}
              src={"/assets/image/KVC_JOIN_US_7-100.jpg"}
            />
          </div>
        </div>
      </Flex>

      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        className={"join-us-page--qr-code"}
      >
        <Text size={"SIZE24"} transform={"uppercase"} color={"white"}>
          Join our network
        </Text>
        <img
          className={"join-us-page--qr-code--img"}
          alt={""}
          src={"/assets/image/QR_CODE_NGC.png"}
        />
        <Text
          className={"join-us-page--qr-mail"}
          size={"SIZE18"}
          color={"white"}
        >
          {"info@nanoshine-group.com"}
        </Text>
      </Flex>
    </div>
  );
};
